import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import { deleteCustomer } from '../apiCalls/ApiCalls';

export default function ConfirmDelete({ setLoggedIn, refreshTableData }) {

    const navigate = useNavigate();

    const handleClose = useCallback(() => navigate('/customers', {replace: true}), [navigate]);

    const { customerId } = useParams();

    const confirmDeleteCustomer = () => {
        deleteCustomer(customerId).then(res => {
            console.log(res.data);
            if (!res.data.authorized) setLoggedIn(false);
            else {
                refreshTableData();
                handleClose();
            }
        })
        
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Usunięcie Klienta
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Czy na pewno chcesz usunąć tego Klienta?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Anuluj</Button>
                <Button onClick={confirmDeleteCustomer} autoFocus>
                    Potwierdź usunięcie
                </Button>
            </DialogActions>
        </Dialog>
    )
}