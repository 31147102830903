import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Button, IconButton, Stack } from "@mui/material";

const columns = [
    {
        id: 'name',
        label: 'Nazwa',
        minWidth: 200
    },
    {
        id: 'nip',
        label: 'NIP',
        minWidth: 130
    },
    {
        id: 'licenses',
        label: 'Ilość licencji',
        minWidth: 100,
    },
    {
        id: 'actions',
        label: 'Operacje',
        minWidth: 170,
        align: 'right',
    },
];

export default function Customers({ setLoggedIn, rows, refreshTableData }) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    

    useEffect(() => {
        console.log("refresh grid data")
        refreshTableData();
    }, [])

    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <div>
            {console.log("rows")}
            {console.log(rows)}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {
                                                            column.id === 'actions' ?
                                                                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                                                    <Button component={Link} to={`/customers/licenses/${row.id}`} size="small" variant="outlined" startIcon={<BusinessCenterIcon />}>
                                                                        Licencje
                                                                    </Button>

                                                                    <IconButton component={Link} to={`/customers/edit/${row.id}`} size="small" variant="outlined" >
                                                                        <EditIcon />
                                                                    </IconButton>

                                                                    <IconButton component={Link} to={`/customers/delete/${row.id}`} size="small" variant="contained" >
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Stack>
                                                                :
                                                                value
                                                        }
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Outlet />
        </div>
    )
}