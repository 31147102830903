import { Card, CardContent, FormControl, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import { logIn } from '../apiCalls/ApiCalls';
import { useState } from "react";


export default function LoginBox({ setLoggedIn }) {

    const [credentials, setCredentials] = useState({ login: "", pass: "" });

    const onChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        })
    }

    const logInApp = () => {
        logIn(credentials.login, credentials.pass).then(res => {
            console.log(res.data);
            console.log(setLoggedIn)
            setLoggedIn(res.data.authorized);
        })
    }

    return (
        <div style={{margin:"40px"}}>
            <Stack direction="column" spacing={1}>
                <FormControl variant="standard">
                    <TextField
                        required
                        id="outlined-required"
                        label="Nazwa użytkownika"
                        name="login"
                        value={credentials.login}
                        onChange={onChange}
                    />
                </FormControl>
                <FormControl variant="standard">
                    <TextField
                        id="standard-password-input"
                        label="Hasło"
                        type="password"
                        autoComplete="current-password"
                        name="pass"
                        value={credentials.pass}
                        onChange={onChange}
                    />
                </FormControl>
                <Button onClick={logInApp} variant="contained" endIcon={<SendIcon />}>
                    Zaloguj
                </Button>
            </Stack>
        </div>
    )
}