import { useState, useEffect } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
    Outlet
} from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { getLoginStatus, logOut, getCustomers } from '../apiCalls/ApiCalls';
import LoginBox from "./LoginBox";
import Customers from "./Customers";
import ConfirmDelete from "./ConfirmDelete";
import EditCustomer from "./EditCustomer";
import Licenses from "./Licenses";

export default function Main() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [anchorElMain, setAnchorElMain] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [rows, setRows] = useState([]);

    const refreshTableData = () => {
        getCustomers().then(res => {
            console.log(res.data);
            if (!res.data.authorized) setLoggedIn(false);
            else {
                setRows(res.data.customers);
            }
        })

    }

    useEffect(() => {
        checkLoggedIn();
    }, [])

    const checkLoggedIn = () => {
        getLoginStatus().then(res => {
            setLoggedIn(res.data.authorized);
        })
    }

    const logOutApp = () => {
        logOut().then(res => {
            setLoggedIn(res.data.authorized);
        })
    }

    const handleMainMenu = (event) => {
        setAnchorElMain(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElMain(null);
        setAnchorElUser(null);
    };

    const handleUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const generateAppBar = () => (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMainMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElMain}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElMain)}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to="/customers" onClick={handleClose}>Lista Klientów</MenuItem>
                    <MenuItem component={Link} to="/customers/new" onClick={handleClose}>Dodaj Klienta</MenuItem>
                </Menu>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Zarządzanie Klientami
                </Typography>

                <div>
                    {
                        loggedIn ?
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleUserMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            :
                            <></>
                    }

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={logOutApp}>Wyloguj się</MenuItem>

                    </Menu>
                </div>

            </Toolbar>
        </AppBar>
    )

    const mainApp = () => (
        <>

            <Routes>
                <Route path="/customers" element={<Customers setLoggedIn={setLoggedIn} rows={rows} refreshTableData={refreshTableData} />}>
                    <Route path="edit/:customerId" element={<EditCustomer setLoggedIn={setLoggedIn} refreshTableData={refreshTableData} />} />
                    <Route path="delete/:customerId" element={<ConfirmDelete setLoggedIn={setLoggedIn} refreshTableData={refreshTableData} />} />
                    <Route path="licenses/:customerId" element={<Licenses setLoggedIn={setLoggedIn} refreshTableData={refreshTableData} />} />
                    <Route path="new" element={<EditCustomer newCustomer={true} setLoggedIn={setLoggedIn} refreshTableData={refreshTableData} />} />
                </Route>
                <Route path="/" element={loggedIn ? <Navigate replace to="/customers" /> : <Navigate replace to="/login" />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </>
    )

    return (
        <BrowserRouter>
            {generateAppBar()}
            {loggedIn ? mainApp() : <LoginBox setLoggedIn={setLoggedIn} />}
        </BrowserRouter>
    )
}