import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function Licenses() {

    const navigate = useNavigate();

    const handleClose = useCallback(() => navigate('/customers', { replace: true }), [navigate]);

    const { customerId } = useParams();

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>Licencje</DialogTitle>
            <DialogContent>
                tutaj trzeba coś dodać
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Anuluj</Button>
                <Button>Zapisz</Button>
            </DialogActions>
        </Dialog>
    )
}