import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getCustomer, saveCustomer } from '../apiCalls/ApiCalls';

export default function EditCustomer({ newCustomer, setLoggedIn, refreshTableData }) {

    const navigate = useNavigate();

    const handleClose = useCallback(() => navigate('/customers', { replace: true }), [navigate]);

    const { customerId } = useParams();

    const [formData, setFormData] = useState({
        id: null,
        name: "",
        nip: "",
        description: "",
        addDate: "",
        licences: "",
    });

    const handleSaveCustomer = () => {

        saveCustomer(formData).then(res => {
            console.log(res.data);
            if (!res.data.authorized) setLoggedIn(false);
            else {
                refreshTableData();
                handleClose();
            }
        })
    }

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    useEffect(() => {
        !newCustomer && getCustomer().then(res => {
            console.log(res.data);
            if (!res.data.authorized) setLoggedIn(false);
            else {
                console.log(res.data.customerInfo)

                setFormData({
                    id: res.data.customerInfo.id,
                    name: res.data.customerInfo.name,
                    nip: res.data.customerInfo.nip,
                    description: res.data.customerInfo.description,
                    addDate: res.data.customerInfo.addDate,
                    licences: res.data.customerInfo.licences,
                })
            }
        })
    }, [])

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>Edycja Klienta</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Nazwa Klienta"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={formData.name}
                />

                <TextField
                    margin="dense"
                    name="nip"
                    label="NIP"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={formData.nip}
                />

                <TextField
                    margin="dense"
                    name="description"
                    label="Opis"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={formData.description}
                />

                {!newCustomer ?

                    <>
                        <TextField
                            margin="dense"
                            name="addDate"
                            label="Data dodania"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.addDate}
                            disabled
                        />

                        <TextField
                            margin="dense"
                            name="licences"
                            label="Data dodania"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.licences}
                            disabled
                        />
                    </>
                    :
                    <></>}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Anuluj</Button>
                <Button onClick={handleSaveCustomer}>Zapisz</Button>
            </DialogActions>
        </Dialog>
    )

}