import axios from 'axios';

const base_url = '/api/api.php?function=';
const url_checkStatus = 'checkStatus'
const url_logout = 'logout'
const url_login = 'login'
const url_getCustomers = 'getCustomers'
const url_deleteCustomer = 'deleteCustomer'
const url_getCustomer = 'getCustomer'
const url_saveCustomer = 'saveCustomer'

const getLoginStatus = () => {
    return axios.get(base_url + url_checkStatus, {withCredentials: true})
}

const logOut = () => {
    return axios.get(base_url + url_logout, {withCredentials: true})
}

const logIn = (user, password) => {
    return axios.post(base_url + url_login, {user, password}, {withCredentials: true})
}

const getCustomers = () => {
    return axios.get(base_url + url_getCustomers, {withCredentials: true})
}

const deleteCustomer = (id) => {
    return axios.post(base_url + url_deleteCustomer, {id}, {withCredentials: true})
}

const getCustomer = (id) => {
    return axios.post(base_url + url_getCustomer, {id}, {withCredentials: true})
}

const saveCustomer = (customer) => {
    return axios.post(base_url + url_saveCustomer, {customer}, {withCredentials: true})
}

export {getLoginStatus, logOut, logIn, getCustomers, deleteCustomer, getCustomer, saveCustomer}